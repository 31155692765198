"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyEvents = void 0;
var applyEventAction = function (data, action) {
    var changed = false;
    switch (action.action) {
        case 'addTag': {
            if (!data.tags) {
                data.tags = [];
            }
            if (data.tags.indexOf(action.value) < 0) {
                data.tags.push(action.value);
                changed = true;
            }
            break;
        }
        case 'removeTag': {
            if (!data.tags || !data.tags.length) {
                return false;
            }
            var index = data.tags.indexOf(action.value);
            if (index >= 0) {
                data.tags.splice(index, 1);
                changed = true;
            }
            break;
        }
    }
    return changed;
};
var isValidEvent = function (event) {
    var _a, _b;
    return event.type == 'userAction' && ((_a = event.targetIds) === null || _a === void 0 ? void 0 : _a.length) && ((_b = event.actions) === null || _b === void 0 ? void 0 : _b.length);
};
var applyEventDate = function (entry, event) {
    if (!event.date) {
        return;
    }
    else if (!entry.updated || entry.updated < event.date) {
        entry.updated = event.date;
    }
};
var applyEvents = function (entries, events) {
    var changedEntries = [];
    events.filter(isValidEvent).forEach(function (event) {
        var eventId = event.id;
        var targetEntries = event.targetIds
            .filter(function (entryId) { return entries.has(entryId); })
            .map(function (entryId) { return entries.get(entryId); })
            .filter(function (entry) { return !entry.appliedEventIds || entry.appliedEventIds.indexOf(eventId) < 0; });
        targetEntries.forEach(function (entry) {
            var changed = false;
            event.actions.forEach(function (action) {
                changed = applyEventAction(entry, action) || changed;
            });
            if (!entry.appliedEventIds) {
                entry.appliedEventIds = [];
            }
            entry.appliedEventIds.push(event.id);
            if (changed) {
                changedEntries.push(entry);
                applyEventDate(entry, event);
            }
        });
    });
    return changedEntries;
};
exports.applyEvents = applyEvents;
